import React from "react";
import styled from "@emotion/styled";
import ABOUT from "../content/about";
import createMarkup from "../utils/createMarkup";

const Styled = styled.div`
  color: #001a45;
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  a {
    text-decoration: none;
    color: mediumblue;
  }

  .email {
    margin-top: -30px;
    margin-bottom: 40px !important;
  }

  .image {
    display: flex;
    flex-flow: column nowrap;
    width: 40%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .content {
    background-color: #f6f2ee;
    flex-flow: column nowrap;
    width: 60%;

    .header {
      font-family: "Martel", serif;
      flex: row;
      padding: 2rem 3rem 1rem;
      margin: 0 0 0.5rem 0;
    }
    .description {
      font-family: "Roboto";
      font-size: 16px;
      flex: row;
      padding: 0.5rem 3rem 1rem 3rem;
      text-align: justify;
      line-height: 1.25rem;
      .section {
        margin-bottom: 1.3rem;
      }
    }
  }

  @media all and (min-width: 200px) and (max-width: 900px) {
    .image {
      width: 100%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .content {
      background-color: #f6f2ee;
      flex-flow: row nowrap;
      width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  #block1 {
    flex-flow: row-reverse;
  }
  .unit {
    background-color: #fff;
    padding-top: 3rem;
  }
  .border {
    flex: row;
    background-color: #001a45;
    height: 3px;
    width: 100%;
  }
  .border-light {
    flex: row;
    background-color: #001a45;
    height: 2px;
    width: 100%;
  }

  h1 {
    background-color: #fff;
    padding: 2rem 0 0;
    margin: 0;
    display: flex;
    width: 100%;
    font-family: "Martel";
    color: #001a45;
    justify-content: center;
  }

  @media all and (min-width: 200px) and (max-width: 900px) {
    #block1 {
      flex-flow: column;
    }
  }
`;

const AboutUs = props => {
  let bio = [];
  let data = ABOUT;

  const renderBio = () => {
    for (let i = 0; i < data.length; i++) {
      bio.push(
        <div className="unit">
          <div className="border-light" />
          <Styled className="styled" id={`block${i + 1}`}>
            <div className={"image"}>
              <img src={data[i].image} alt="bio"></img>
            </div>
            <div className="content">
              <h2 className="header">{data[i].name}</h2>
              <p
                className={"description"}
                dangerouslySetInnerHTML={createMarkup(data[i].description)}
              ></p>
            </div>
          </Styled>
          <div className="border" />
        </div>
      );
    }
    return bio;
  };

  return (
    <Wrapper id="who-we-are">
      <h1>Who We Are</h1>
      {renderBio()}
    </Wrapper>
  );
};

export default AboutUs;
