import React, { useState, useRef, useEffect } from "react";

import AboutUs from "./components/AboutUs";
import Description from "./components/Description";
import Nav from "./components/Nav";
import ContactUs from "./components/ContactUs";
import Summary from "./components/Summary";

const AppInner = () => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    setSticky(ref.current.getBoundingClientRect().top <= 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);
  return (
    <div className={`sticky-wrapper${isSticky ? " sticky" : ""}`} ref={ref}>
      <div>
        <Nav />
      </div>
      <div>
        <Summary path="#summary" />
        <AboutUs path="#who-we-are" />
        <Description />
        <ContactUs path="#contact-us" />
      </div>
    </div>
  );
};

export default AppInner;
