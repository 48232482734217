import React from "react";
import styled from "@emotion/styled";
import createMarkup from "../utils/createMarkup";
import APPROACH from "../content/approach";
import CLIENTELE from "../content/clientele";
import SERVICES from "../content/services";

const StyledDescription = styled.div`
  padding: 1rem 3rem 3rem;

  :nth-of-type(2n + 1) {
    background-color: #f6f2ee;
  }
  :nth-of-type(2n) {
    background-color: #fff;
  }
  .section {
    margin-top: 1rem;
  }
  h2 {
    font-family: "Martel";
  }
`;

const StyledWrapper = styled.div`
  margin-top: 3rem;
  .header {
    text-align: center;
    background-color: #fff;
    margin: 0;
    padding: 2rem 0 0;
    width: 100%;
    h1 {
      margin: 0;
      font-family: "Martel";
      color: #001a45;
    }
  }
`;

const Description = props => {
  let content = [SERVICES, APPROACH, CLIENTELE];
  let blocks = [];

  const parseID = myStr => {
    myStr = myStr.toLowerCase();
    myStr = myStr.replace(/(^\s+|[^a-zA-Z0-9 ]+|\s+$)/g, ""); //this one
    myStr = myStr.replace(/\s+/g, "-");
    return myStr;
  };

  for (let i = 0; i < content.length; i++) {
    console.log(content[i].description);
    let block = (
      <StyledDescription id={parseID(content[i].name)}>
        <h2 dangerouslySetInnerHTML={createMarkup(content[i].name)}></h2>
        <div dangerouslySetInnerHTML={createMarkup(content[i].description)} />
      </StyledDescription>
    );
    blocks.push(block);
  }
  return (
    <StyledWrapper>
      <div className="header">
        <h1>What We Do</h1>
      </div>
      {blocks}
    </StyledWrapper>
  );
};

export default Description;
