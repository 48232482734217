import React from "react";
import styled from "@emotion/styled";
import CONTACT from "../content/contact";
import createMarkup from "../utils/createMarkup";

const StyledContainer = styled.div`
  background-color: #fff;
  margin-top: 3rem;
  padding: 2rem;
  display: flex;
  flex-flow: row nowrap;
  h1 {
    font-family: "Martel";
  }
  .contact {
    margin-right: auto;
  }
  .section {
    margin-bottom: 1rem;
  }
`;

const ContactUs = () => {
  return (
    <StyledContainer id="contact-us">
      <div className="contact">
        <h1>{CONTACT.name}</h1>
        <div
          className="section"
          dangerouslySetInnerHTML={createMarkup(CONTACT.address)}
        ></div>
        <div className="section">{CONTACT.phone}</div>
        <div className="section">{CONTACT.fax}</div>
        <div className="section">{CONTACT.email}</div>
      </div>
      <div className="map">
        <iframe
          width="600"
          height="450"
          frameborder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=118%20E%2028th%20St%20%23306%2C%20New%20York%2C%20NY%2010016&key=AIzaSyAzps39tR9q96m7HTvH8dQ4itmr-eezxYA"
          allowfullscreen
        ></iframe>
      </div>
    </StyledContainer>
  );
};

export default ContactUs;
