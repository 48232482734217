import React from "react";
import styled from "@emotion/styled";
import SUMMARY from "../content/summary";
import createMarkup from "../utils/createMarkup";

const StyledSummary = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background-color: #e7eaef;
  color: #001a45;
  font-family: "Roboto";
  padding: 3rem;
  align-content: center;
  justify-content: center;
  span {
    font-family: "Martel";
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 1rem;
    width: 100%;
  }
  .section {
    justify-content: center;
    align-content: center;
    text-align: center;
    margin-bottom: 1rem;
    width: 100%;

    &:nth-of-type(2n) {
      padding: 0 1.5rem;
    }
  }
`;

const StyledWrapper = styled.div`
  .border {
    flex: row;
    background-color: #001a45;
    height: 3px;
    width: 100%;
  }
`;

const Summary = () => {
  return (
    <StyledWrapper className="sticky-inner">
      <StyledSummary>
        <div dangerouslySetInnerHTML={createMarkup(SUMMARY.description)} />
      </StyledSummary>
      <div className="border" />
    </StyledWrapper>
  );
};

export default Summary;
