import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { HashLink as Link } from "react-router-hash-link";

const StyledNav = styled.div`
  list-style: none;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  justify-content: flex-end;
  background: #001a45;

  .links {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-flow: row wrap;
    justify-content: flex-end;
  }
  .menu {
    display: none;
  }

  .title {
    font-family: "Martel";
    font-size: 18px;
    font-weight: 700;
    margin-right: auto;
    padding: 1em;
    color: white;
  }
  .navigation a {
    text-align: center;
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navigation li:last-of-type a {
    border-bottom: none;
  }

  a {
    text-decoration: none;
    display: block;
    padding: 1em;
    color: white;

    a:hover {
      background: #173363;
    }
  }

  /* Small screens */
  @media all and (max-width: 900px) {
    /* On small screens, we are no longer using row direction but column */

    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    padding: 0;
    position: fixed;
    margin-bottom: 66px;

    .menu {
      display: inline-block;
    }

    .links {
      display: none;
    }

    .links.visible {
      display: flex !important;
      flex-flow: column wrap;
    }
  }
`;

const Nav = () => {
  const navSections = [
    "services",
    "our approach",
    "clientele",
    "who we are",
    "contact us"
  ];
  const [links, setLinks] = useState();
  const [visibleLinks, setVisibleLinks] = useState(false);
  const convertSpaceToDash = param => {
    return param.replace(/\s+/g, "-").toLowerCase();
  };
  String.prototype.capitalize = function() {
    return this.replace(/(?:^|\s)\S/g, function(a) {
      return a.toUpperCase();
    });
  };

  const toggleMenu = () => {
    setVisibleLinks(!visibleLinks);
    console.log("HI", visibleLinks);
  };

  useEffect(() => {
    let parsedLinks = [];
    const createLinks = sections => {
      for (let i = 0; i < sections.length; i++) {
        let href = convertSpaceToDash(sections[i]);
        let link = <Link to={`#${href}`}>{sections[i].capitalize()}</Link>;
        parsedLinks.push(link);
      }
    };
    createLinks(navSections);
    setLinks(parsedLinks);
  }, []);

  useEffect(() => {
    // Decode entities in the URL
    // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split("#");
      if (hashParts.length > 2) {
        const hash = hashParts.slice(-1)[0];
        document.querySelector(`#${hash}`).scrollIntoView();
      }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
  }, [window]);

  return (
    <StyledNav className="sticky-inner">
      <div className="title" onClick={toggleMenu}>
        Budnick CPA{" "}
        <span class="menu">
          <i class="fas fa-bars"></i>
        </span>
      </div>
      <span className={`links ${visibleLinks ? "visible" : ""}`}>{links}</span>
    </StyledNav>
  );
};

export default Nav;
